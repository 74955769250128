// src/components/Chat.js

import React, { useEffect, useState } from 'react';
import SamJs from 'sam-js';
import WarningModal from './WarningModal';
import './Chat.css';

const Chat = () => {
    const [userId, setUserId] = useState(null);
    const [userInput, setUserInput] = useState('');
    const [displayedResponse, setDisplayedResponse] = useState('');
    const [emotion, setEmotion] = useState('confused.gif');
    const [showWarning, setShowWarning] = useState(true);
    const sam = new SamJs();

    useEffect(() => {
        if (!showWarning) {
            const getUserId = async () => {
                try {
                    const response = await fetch("https://api.digitalsoul.website/api/generate-user-id/");
                    if (!response.ok) throw new Error("error obtaining user_id");
                    const data = await response.json();
                    setUserId(data.user_id);
                } catch (error) {
                    console.error("api call error", error);
                    setUserId(null);
                }
            };
            getUserId();
        }
    }, [showWarning]);

    const sendMessage = async () => {
        if (userInput.trim() === "") return;

        setUserInput('');
        playSound("button-sound");

        const glitchImages = ["noise_1.gif", "noise_2.gif", "noise_3.gif", "noise_4.gif"];
        const randomGlitch = glitchImages[Math.floor(Math.random() * glitchImages.length)];
        changeSoulImage(randomGlitch);

        const response = await sendMessageToAPI(userInput);
        if (response) {
            displayTypingEffect(response.message);
            changeSoulImage(response.emotion);
            await speak(response.message);
        }

    };

    const sendMessageToAPI = async (message) => {
        const glitches = ["noise_1.gif", "noise_2.gif", "noise_3.gif", "noise_4.gif"];
        const randomGlitch = glitches[Math.floor(Math.random() * glitches.length)];
        changeSoulImage(randomGlitch);

        try {
            const response = await fetch("https://api.digitalsoul.website/api/chat/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ user_id: userId, message }),
            });

            if (!response.ok) throw new Error("api call error");
            const data = await response.json();
            const parsedResponse = JSON.parse(data.response);
            return {
                message: parsedResponse.message,
                emotion: parsedResponse.emotion
            };
        } catch (error) {
            console.error("api call error", error);
            return {
                message: "something went wrong...",
                emotion: "confused.gif"
            };
        }
    };

    const displayTypingEffect = async (text) => {
        setDisplayedResponse('');
        let index = -1;

        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (index < text.length - 1) {
                    setDisplayedResponse(prev => prev + text[index]);
                    // playSound("type-sound");
                    index++;
                } else {
                    clearInterval(interval);
                    resolve();
                }
            }, 50);
        });
    };

    const playSound = (soundId) => {
        const sound = document.getElementById(soundId);
        if (sound) {
            sound.currentTime = 0;
            sound.play();
        }
    };

    const speak = async (text) => {
        try {
            await sam.speak(text);
        } catch (error) {
            console.error("error speaking", error);
        }
    };

    const changeSoulImage = (gifName) => {
        setEmotion(gifName);
    };

    // Функция для проигрывания фона
    const playBackgroundMusic = () => {
        const music = document.getElementById('background-music');
        if (music) {
            music.play();
        }
    };

    return (
        <div id="chat-container">
            {showWarning && (
                <WarningModal
                    onClose={() => {
                        setShowWarning(false);
                        playBackgroundMusic();
                    }}
                />
            )}
            {!showWarning && (
                <>
                    <img id="soul-image" src={emotion} alt="soul" />
                    <div id="response">{displayedResponse}</div>
                    <input
                        type="text"
                        id="user-input"
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") sendMessage();
                        }}
                        placeholder="Speak to the soul..."
                    />
                    <button id="send-btn" onClick={sendMessage}>SEND</button>
                    <audio id="button-sound" src="button_press.wav" preload="auto"></audio>
                    <audio id="type-sound" src="type_sound.wav" preload="auto"></audio>
                    <audio autoPlay={true} id="background-music" src="music.wav" preload="auto" loop></audio> {}
                </>
            )}
        </div>
    );
};

export default Chat;
