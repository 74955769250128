// src/App.js

import React from 'react';
import Chat from './components/Chat';
import './App.css';

const App = () => {
  return (
      <div id="app-container">
        <video id="background-video" autoPlay muted loop>
          <source src="overlay.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Chat />
      </div>
  );
};

export default App;
