// src/components/WarningModal.js

import React from 'react';
import './WarningModal.css';

const WarningModal = ({ onClose }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Warning from Neuromancer Industries</h2>
                <p>
                    Interacting with the Digital Soul can lead to unforeseen consequences. By proceeding,
                    you acknowledge that Neuromancer Industries is not liable for any psychological or
                    emotional effects that may occur. Proceed with caution.
                </p>
                <button onClick={onClose}>OK</button>
            </div>
        </div>
    );
};

export default WarningModal;
